<template>
  <b-card v-if="modeerror == 0" >
    <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button @click.prevent="add" variant="primary"> <feather-icon icon="PlusIcon" class="mr-50" />Tambah </b-button>
        <b-modal v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
          <b-form>
            <b-form-group>
              <label for="name">Nama Voucher</label>
              <b-form-input v-model="form.name" id="name" type="text" placeholder="Ex : Jum'at Berkah" />
            </b-form-group>
            <b-form-group>
              <b-form-radio-group
        id="radio-group-1"
        v-model="form.type"
        
        name="radio-sub-component1"
      >
        <b-form-radio value="nominal">Nominal</b-form-radio>
        <b-form-radio value="percentage">Persentase</b-form-radio>
      </b-form-radio-group>
            </b-form-group>
            <b-form-group v-if="form.type == 'nominal'">
              <label for="value">Nilai Voucher Nominal</label>
              <b-input-group prepend="Rp">
              <b-form-input v-model="form.value" @keyup="doFormatRupiah()" id="value" type="text" placeholder="1000" />
              </b-input-group>
            </b-form-group>
            <b-form-group v-else>
              <label for="value">Nilai Voucher Persentase</label>
              <b-input-group append="%">
              <b-form-input v-model="form.value" id="value" type="text" placeholder="1000" />
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <label for="reward">Deskripsi</label>
              <b-form-input v-model="form.description" id="description" placeholder="Deskripsi" />
            </b-form-group>
            <b-form-group>
              <b-form-radio-group
        id="radio-group-2"
        v-model="form.is_active"
        
        name="radio-sub-component"
      >
        <b-form-radio value="1">Aktif</b-form-radio>
        <b-form-radio value="0">Nonaktif</b-form-radio>
      </b-form-radio-group>
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Tambah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
          <b-form-group
            class="mb-0"
            label-size="sm"
            :label="$t('Per page')"
            label-for="perPage"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getData()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="
                    filter = '';
                    getData();
                  "
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(value)="{item}">
              {{ item.discount_mode == 'nominal' ? formatRupiah(item.value) : formatRupiah(item.value) +'%' }}
          </template>
          <template #cell(owned_by)="{item}">
              {{ item.owned_by == 'pusat' ? item.owned_by.toUpperCase() : item.member.nama_lengkap }}
          </template>
          <!-- <template #cell(discount_mode)="row">
            <b-badge variant="success" v-if="row.value == 'nominal'"><feather-icon size="40px" icon="CheckCircleIcon" /> Nominal</b-badge>
            <b-badge variant="danger" v-else><feather-icon size="30" icon="XCircleIcon" /> Persentage</b-badge>
          </template> -->
          <template #cell(is_active)="row">
            <strong class="text-center">
              <b-badge variant="success" v-if="row.value == 1"><feather-icon size="24" icon="CheckCircleIcon" /> Aktif</b-badge>
              <b-badge variant="danger" v-else><feather-icon size="30" icon="XCircleIcon" /> Nonaktif</b-badge>
            </strong>
          </template>
          <template v-if="isFinance || isOwner|| isOwnerHeykama" #cell(actions)="row">
            <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info" v-if="row.item.owned_by == 'pusat'">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger" v-if="row.item.owned_by == 'pusat'">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
          <template v-else #cell(actions)="row">
            <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info" v-if="row.item.owned_by == 'member'">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger" v-if="row.item.owned_by == 'member'">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
      </b-col>
    </b-row>
  </b-card>
  <b-card v-else class="text-center">
    <!-- <autentikasi-akun></autentikasi-akun> -->
    <sedang-perbaikan></sedang-perbaikan>
  </b-card>
</template>

<script>
import SedangPerbaikan from "../SedangPerbaikan.vue"
import AutentikasiAkun from "../Autentikasi.vue"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BCard,BFormRadio, BFormRadioGroup, BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    SedangPerbaikan,
    AutentikasiAkun,
    BCard,BFormRadio, BFormRadioGroup, BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      modeerror: 0,
      showModalTambah: false,
      showModalEdit: false,
      id: null,
      form: {
        name: null,
		owned_by: null, // jika dibuat oleh pusat, maka nilainya "pusat", jika oleh toko maka nilainya "member"
		member_id: null, // jangan dikirim jika diskon oleh pusat, jika oleh member, kirim member id
		type: "nominal", // wajib dikirim, nilainya nominal atau percentage
		value: 0, // nilai diskon
		is_active: 0, // jika is_active 1 maka diskon akan tampil di hal. checkout konsumen, jika 0 maka tidak tampil
        description: "" // optioanl
      },
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "name", label: "Nama", sortable: true },
        { key: "owned_by", label: "Pembuat", sortable: true },
        // { key: "discount_mode", label: "tipe", sortable: true },
        { key: "value", label: "Nilai", sortable: true },
        { key: "description", label: "Deskripsi", sortable: true },
        { key: "is_active", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
  },
  methods: {
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data " + item.name + " ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: item.id
          }
          // payload.append("fungsi", 1);
          this.$store
            .dispatch("diskon/delete", [payload])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Data berhasil dihapus"
              })
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        nama: "",
        no_diskon: "",
        description: "",
        diskon_utama: null,
      };
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    doFormatRupiah() {
      this.form.value = this.formatRupiah(this.form.value);
    },
    submit() {
      if (this.form.value == null || this.form.value == ""
        || this.form.name == null || this.form.name == ""
      ) {
        this.displayInfo({
          message: "Harap lengkapi form"
        });
        return false;
      }
      if (this.form.type == "percentage" && this.form.value > 100) {
        this.displayInfo({
          message: "Persentase tidak boleh lebih dari 100%, harap ubah nilai diskon"
        });
        return false;
      }
      let payload = {
        name: this.form.name,
        type: this.form.type,
        value: this.unFormatRupiah(this.form.value),
        description: this.form.description,
        is_active: this.form.is_active,
      }
      if (this.isCabang || this.isMarketing) {
        payload.member_id = this.user.member_id
        payload.owned_by = 'member'
      }
      if (this.id) {
        payload.id = this.id
      }
      if (this.isFinance || this.isOwner|| this.isOwnerHeykama) {
        payload.owned_by = 'pusat'
      }
      this.$store
        .dispatch("diskon/save", [payload])
        .then(() => {
          this.id = null;
          this.form = {
            nama: null,
            type: null,
            description: null,
            is_active: null,
          };
          this.showModalTambah = false;
          this.getData();
          this.displaySuccess({
            message: "Data berhasil disimpan"
          })
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      this.id = item.id;
      // console.log(item);
      this.form = {
        name: item.name,
        value: item.value,
        description: item.description,
        type: item.discount_mode,
        is_active: item.is_active,
      };
      this.showModalTambah = true;
    },
    getData() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      if (this.isCabang || this.isMarketing) {
        var params = {
          member_id: this.user.member_id,
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        };
      } else {
        var params = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };
      }
      this.$store
        .dispatch("diskon/getData", params)
        .then(() => {
      let items = JSON.parse(
        JSON.stringify(this.$store.state.diskon.datas)
      );
      let items_total = this.$store.state.diskon.totals;
          this.items = items;
          this.totalRows = items_total;
        })
        .catch((e) => 
        {
          this.displayError(e)
          console.error(e);
          this.modeerror = 1;
        }
        );
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
